.hero__content {
  padding-top: 20px;
}

.home-header {
  height: 250px;
}

.home-header strong {
  color: #df2020;
  font-weight: bold;
}

.home-header2 {
  height: 100px;
}

.bottomType {
  position: absolute;
  height: 250px;
}

.bottomType span {
  color: #df2020;
  font-weight: bold;

}

.hero__title {
  font-size: 2.5rem;
  line-height: 55px;
}

.hero__title span {
  color: #df2020;
}

.hero__content p {
  font-size: 1rem;
  line-height: 30px;
  color: #777;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}



.order__btn {
  border: none;
  outline: none;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  background: #df2020;
  color: #fff;
  font-weight: 600;
}

.order__btn:hover {
  background: #212245;
}

.order__btn:hover a {
  color: inherit;
  text-decoration: none;
}

.hero__img {
  margin: 2px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (max-width: 768px) {
  .hero__content h5 {
    font-size: 1rem;
  }

  .hero__content p {
    font-size: 0.8rem;
    line-height: 25px;
  }

  .hero__title {
    font-size: 1.5rem;
    line-height: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content {
    text-align: center;
  }

  .order__btn {
    display: block;
    margin: 0 auto;
  }
}