.product__item {
  border: 1px solid #fde4e4;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  height: 100%;
  min-height: 19rem;
}

.product__img {
  margin-bottom: 20px;
  transition: 0.4s;
}

.product__img:hover {
  transform: scale(1.2);
}

.product__content h5 a {
  color: #212245;
  font-style: 1rem;
}

.product__content h5 {
  margin-bottom: 30px;
}

.product__price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #df2020;
}

.addTOCART__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

.addTOCART__btn1 {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  font-size: 0.9rem;
}

@media only screen and (max-width: 992px) {
  .product__content h5 a {
    font-size: 0.8rem;
  }

  .product__item {
    padding: 20px;
  }

  .addTOCart__btn {
    padding: 5px 15px;
    font-size: 0.7rem;
  }
}