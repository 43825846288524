.single__product-content {
  padding-left: 50px;
}

.product__price {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0;
}

.product__price span {
  font-size: 2rem;
}

.extraIngredientsGrid {
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  margin-top: 3rem;
}

.category {
  font-weight: 600;
  color: #212245;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 10px;
}

.category span {
  padding: 5px 10px;
  background: #fde4e4;
  color: #212245;
  border-radius: 5px;
}

.description__content {
  padding: 30px 0px;
}

.description__content p {
  line-height: 30px;
  color: #212245b6;
}

.description {
  color: #df2020;
}

.img__item {
  cursor: pointer;
}

.related__Product-title {
  font-size: 1.4rem;
}

@media only screen and (max-width: 830px) {
  .product__title {
    font-size: 1.2rem;
  }

  .product__price,
  .product__price span {
    font-size: 1rem;
  }

  .category,
  .category span {
    font-size: 0.8rem !important;
  }

  .description__content p {
    font-size: 0.8rem;
  }

  .related__Product-title {
    font-size: 1.2rem;
  }

  .extraIngredientsGrid {
    grid-template-columns: repeat(2, 1fr)
  }
}

@keyframes animation {
  from {
    top: 0;
  }

  to {
    top: 2rem;
  }
}

.updateCartNotifiation {
  width: -webkit-fill-available;
  top: 2rem;
  height: 3rem;
  font-size: larger;
  font-weight: bold;
  background-color: #4BB543;
  border-radius: 10px;
  position: fixed;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9999;
  animation: 1s animation;
}

@media only screen and (max-width: 776px) {
  .product__images {
    display: flex;
    flex-direction: row;
  }

  .product__main-img {
    margin-bottom: 20px;
  }

  .addTOCART__btn {
    margin-top: 10px;
  }

  .category.mb-5 {
    display: inline-block;
    margin-right: 10px;
  }

  .extraIngredientsGrid {
    grid-template-columns: repeat(1, 1fr)
  }
}