.footer {
  padding: 50px 0px;
  background: #fde4e4;
  display: flex;
  text-align: start;
  justify-content: center;
  gap: 8rem;
  align-items: center;
}

.footer__logo img {
  width: calc(100% - 85%);
}

.myLogo:hover {
  color: #df2020;
}

.myCursor {
  cursor: pointer;
}

.FbmyLogo:hover {
  color: rgb(66, 102, 178)
}

.TwmyLogo:hover {
  color: rgb(29, 161, 242)
}

.GomyLogo:hover {
  color: rgb(37, 211, 102)
}

.GimyLogo:hover {
  color: rgb(23, 21, 21)
}

.InmyLogo:hover {
  color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
}

.LimyLogo:hover {
  color: rgb(0, 114, 177)
}

.footer__logo h5 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__logo p {
  font-size: 0.9rem;
  color: #333;
  line-height: 29px;
}

.delivery__time-item {
  display: flex;
  margin: 0;
  gap: 0.7rem;
  background: transparent !important;
  justify-content: center;
}

.delivery__time-item span {
  font-weight: 600;
  color: #212245;
  margin: 0;
}

.delivery__time-item p {
  color: #212245;
}

.footer__title {
  font-size: 1.1rem;
  color: #212245;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .footer__logo img {
    width: calc(100% - 85%);
  }

  .footer__title {
    font-size: 0.9rem;
  }

  .delivery__time-item span {
    font-size: 0.8rem;
  }

  .delivery__time-item p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}