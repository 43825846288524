.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
  top: 0;
}

@keyframes animation {
  from {
    top: -100px;
  }

  to {
    top: 0px;
  }
}

.header__shrink {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 5px 5px 15px -5px #d7d6d6;
  animation-name: animation;
  animation-duration: 0.5s;
}

.logo {
  text-align: center;
  max-width: 200px;
}

.logo:hover {
  cursor: pointer;
}

.logo img {
  width: calc(100%);
  object-fit: contain;
  margin-top: -20px;
  justify-content: left;
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100%);
  }

  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 0.9rem;
  }

  .mobile__menu {
    display: block;
  }

  .show__menu {
    display: block;
  }
}

.header__closeButton {
  width: fit-content;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
  margin: 0.5rem 0;
  display: none;
}

.header__closeButton span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .close__button {
    display: flex;
  }

  .header__closeButton {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
  }

  .logo img {
    margin-top: -5px !important;
    width: calc(90%);
  }

  .logo h5 {
    margin-top: -55px;
  }

  .header__closeButton {
    display: flex;
  }
}

@media only screen and (max-width: 480px) {
  .menu {
    width: 100%;
  }

  .header__closeButton {
    display: flex;
  }
}

.close__button {
  width: fit-content;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0.5rem;
  right: 0rem;
}

.close__button span i {
  font-size: 1.1rem;
  background: #212245;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}